










import Vue from 'vue'
import Component from 'vue-class-component'

@Component({ name: 'notFoundView' })
export default class NotFoundView extends Vue {
}
